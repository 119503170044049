import React, { useState } from "react";
import "./bankPaymentDrawer.scss";
import BankPaymentContent from "./BankPaymentContentS2";
import BankPaymentContentS2 from "./BankPaymentContentS2";

const BankPaymentDrawerS2 = ({
  cancel,
  setTipsDrawerActive,
  activeOrder,
  setActiveOrder,
}) => {
  return (
    <div className="bank-payment-drawer">
      <div className="shadow" onClick={cancel}></div>
      <BankPaymentContentS2
        setTipsDrawerActive={setTipsDrawerActive}
        cancelClick={cancel}
        activeOrder={activeOrder}
        setActiveOrder={setActiveOrder}
      />
    </div>
  );
};

export default BankPaymentDrawerS2;
