import React, { useState } from "react";
import "./deliveryOptions.scss";
import Toogle from "../../atoms/Toogle/Toogle";
import ListItem from "../../../ds/ListItem/ListItem";
import { Returntext } from "../../../pages/Landings/common/returnText";
import { useContent, useLanguages } from "../../../hooks/content";
import { ReactComponent as ShieldIcon } from "../../../icons/shield.svg";
import { ReactComponent as BoxIcon } from "../../../icons/box-g.svg";

const DeliveryOptions = ({ ringBell, setRingBell, atDoor, setAtDoor }) => {
  const ProductDetailsTitle = (main) => (
    <div className="txt-head">
      <div className="main">{main}</div>
    </div>
  );
  const { lang } = useLanguages();
  const { contentManagementData } = useContent();
  return (
    <div className="delivery-options">
      {ProductDetailsTitle(
        Returntext(contentManagementData, "ShipmentInformation", lang)
      )}

      <ListItem
        hasBorder={false}
        hasPadding={false}
        hasIcon={true}
        headline={Returntext(contentManagementData, "DSB1T", lang)}
        description={Returntext(contentManagementData, "DSB1B", lang)}
        icon={<ShieldIcon />}
        buttons={
          <Toogle
            disabled={false}
            active={atDoor}
            onClick={() => setAtDoor(!atDoor)}
          />
        }
      />

      {/* <ListItem
        hasBorder={false}
        hasPadding={false}
        hasIcon={true}
        headline={Returntext(contentManagementData, "DSB2T", lang)}
        description={Returntext(contentManagementData, "DSB2B", lang)}
        icon={<BoxIcon />}
        buttons={
          <Toogle
            disabled={false}
            active={ringBell}
            onClick={() => setRingBell(!ringBell)}
          />
        }
      /> */}
    </div>
  );
};

export default DeliveryOptions;
