import React, { useEffect, useState, useRef } from "react";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import "./Invoice.scss";
import gzavniliLogo from "./gzavnili-logo.png";
import { useParams } from "react-router-dom";
import axios from "axios";
import Cookies from "js-cookie";
import { Returntext } from "../../../pages/Landings/common/returnText";
import { useContent, useLanguages } from "../../../hooks/content";
import BtnStandardIconText from "../../../ds/BtnStandardIconText/BtnStandardIconText";
import { ReactComponent as DownloadIcon } from "../../../icons/download3.svg";

/**
 * cropCanvas:
 * Trims away pure-white (or near-white) margins from the canvas edges.
 * Adjust 'tolerance' if your background isn't perfectly white (0 = exact white).
 */
function cropCanvas(canvas, tolerance = 0) {
  const ctx = canvas.getContext("2d");
  const { width, height } = canvas;
  const imageData = ctx.getImageData(0, 0, width, height).data;

  let top = null,
    left = null,
    right = null,
    bottom = null;

  for (let y = 0; y < height; y++) {
    for (let x = 0; x < width; x++) {
      const index = (y * width + x) * 4;
      const r = imageData[index];
      const g = imageData[index + 1];
      const b = imageData[index + 2];
      const a = imageData[index + 3];

      // If this pixel is NOT pure white (within tolerance), update boundaries
      if (
        !(
          r >= 255 - tolerance &&
          g >= 255 - tolerance &&
          b >= 255 - tolerance &&
          a === 255
        )
      ) {
        if (top === null) top = y;
        if (left === null || x < left) left = x;
        if (right === null || x > right) right = x;
        bottom = y;
      }
    }
  }

  // If the entire canvas was white, return the original (no cropping)
  if (top === null) return canvas;

  const cropWidth = right - left + 1;
  const cropHeight = bottom - top + 1;

  const croppedCanvas = document.createElement("canvas");
  croppedCanvas.width = cropWidth;
  croppedCanvas.height = cropHeight;
  const croppedCtx = croppedCanvas.getContext("2d");

  croppedCtx.drawImage(
    canvas,
    left,
    top,
    cropWidth,
    cropHeight,
    0,
    0,
    cropWidth,
    cropHeight
  );

  return croppedCanvas;
}

const InvoiceSingle = () => {
  const [invoice, setInvoice] = useState(null);
  const [date, setDate] = useState("");
  const [invN, setInvN] = useState("");

  const userData = JSON.parse(localStorage.getItem("user"));
  const { lang } = useLanguages();
  const { contentManagementData } = useContent();

  // This ref includes the ENTIRE invoice (including the button):
  const invoiceRef = useRef(null);

  // Handle PDF Download
  const handleDownloadPDF = async () => {
    try {
      // 1) Capture the invoice, ignoring elements with class "btn-standard-icon-text"
      const originalCanvas = await html2canvas(invoiceRef.current, {
        scale: 2,
        ignoreElements: (element) =>
          element.classList?.contains("btn-standard-icon-text"),
      });

      // 2) Crop out any pure-white margins
      const croppedCanvas = cropCanvas(originalCanvas, 0 /* tolerance */);

      // 3) Convert cropped canvas to an image
      const imgData = croppedCanvas.toDataURL("image/png");

      // 4) Create a multi-page PDF (A4)
      const pdf = new jsPDF("p", "pt", "a4");
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = pdf.internal.pageSize.getHeight();

      // Scale cropped canvas to fit PDF width
      const ratio = pdfWidth / croppedCanvas.width;
      const scaledWidth = pdfWidth;
      const scaledHeight = croppedCanvas.height * ratio;

      let remainingHeight = scaledHeight;
      let positionY = 0;

      // Place the entire scaled image on the first page
      pdf.addImage(imgData, "PNG", 0, 0, scaledWidth, scaledHeight);
      remainingHeight -= pdfHeight;

      // 5) Add more pages if the image is taller than one page
      while (remainingHeight > 0) {
        positionY = scaledHeight - remainingHeight; // how much is "above" the new page
        pdf.addPage();
        pdf.addImage(imgData, "PNG", 0, -positionY, scaledWidth, scaledHeight);
        remainingHeight -= pdfHeight;
      }

      // 6) Download the PDF
      pdf.save(`gzavnili_invoice${invN}.pdf`);
    } catch (err) {
      console.error("Error generating PDF:", err);
    }
  };

  useEffect(() => {
    // Retrieve the stored order string from sessionStorage
    const storedOrderString = sessionStorage.getItem("selectedOrder");
    setDate(sessionStorage.getItem("invoice_date"));
    setInvN(sessionStorage.getItem("invoice_num"));
    if (storedOrderString) {
      // Parse the JSON back into an object
      const parsedOrder = JSON.parse(storedOrderString);
      setInvoice(parsedOrder);
    }
  }, []);

  return (
    <div className="invoice" ref={invoiceRef}>
      <div className="cont">
        {/* HEADER */}
        <div className="header">
          <div className="left">
            <div className="top">
              <div className="mt">ინვოისი</div>
              <div className="st">{invN}</div>
            </div>
            <div className="bottom">
              <div className="i-date">
                ინვოისის თარიღი:{" "}
                <div className="bld">
                  {(() => {
                    const d = new Date(date);
                    return `${d.getDate()} ${d.toLocaleString("default", {
                      month: "long",
                    })}, ${d.getFullYear()}`;
                  })()}
                </div>
              </div>
            </div>
          </div>
          <img src={gzavniliLogo} alt="Gzavnili Logo" className="right" />
        </div>

        {/* BODY */}
        <div className="body">
          <div className="ff">
            <div className="left">
              <div className="ttxt">მყიდველი</div>
              <div className="info-b-c">
                <div className="info-b">
                  <div className="left-b">დასახელება:</div>
                  <div className="right-b">{userData.company_info}</div>
                </div>
                <div className="info-b">
                  <div className="left-b">ტელეფონი:</div>
                  <div className="right-b">{userData.phone}</div>
                </div>
                <div className="info-b">
                  <div className="left-b">მეილი:</div>
                  <div className="right-b">{userData.email}</div>
                </div>
                <div className="info-b">
                  <div className="left-b">ოთახი:</div>
                  <div className="right-b">{userData.room_number}</div>
                </div>
              </div>
            </div>

            <div className="right">
              <div className="ttxt">გამყიდველი</div>
              <div className="info-b-c">
                <div className="info-b">
                  <div className="left-b">დასახელება:</div>
                  <div className="right-b">შპს Gzavnili ge</div>
                </div>
                <div className="info-b">
                  <div className="left-b">ანგ. ნომერი:</div>
                  <div className="right-b">GE95TB7525736020100006GEL</div>
                </div>
                <div className="info-b">
                  <div className="left-b">საიდ კოდი:</div>
                  <div className="right-b">454345201m</div>
                </div>
                <div className="info-b">
                  <div className="left-b">მეილი:</div>
                  <div className="right-b">support@gzavnili.com</div>
                </div>
                <div className="info-b">
                  <div className="left-b">ტელეფონი:</div>
                  <div className="right-b">0322 2 25 56 67</div>
                </div>
                <div className="info-b">
                  <div className="left-b">მისამართი:</div>
                  <div className="right-b">
                    ქ. თბილისი, საბურთალოს რაიონი ვინცაძის ქ. N43 ბ, 4ა
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="sf">
            <div className="header-t">
              <div className="tbs">თრექინგი</div>
              <div className="tbs">წონა</div>
              <div className="tbs">საჰაერო გადაზიდვა</div>
            </div>
            <div className="body-t">
              <div className="tbd-wrap" key={invoice?.tracking_number}>
                <div className="tbd">
                  <div className="top-t">{invoice?.tracking_number}</div>
                  <div className="bottom-t">{invoice?.name}</div>
                </div>
                <div className="tbd">{invoice?.weight} კგ</div>
                <div className="tbd">{invoice?.price_gz} ₾</div>
              </div>
            </div>
          </div>
        </div>

        {/* The download button is in the same container, 
            but we'll skip it in HTML2Canvas by ignoring className="btn-standard-icon-text". */}
        <div className="btm">
          <BtnStandardIconText
            mode=""
            txt="ინვოისის ჩამოტვირთვა"
            disabled={false}
            size="standard"
            icon={true}
            mainColor="green"
            iconPosition={"right"}
            svg={<DownloadIcon />}
            onClick={handleDownloadPDF}
          />
        </div>
      </div>
    </div>
  );
};

export default InvoiceSingle;
