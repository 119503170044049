import React from "react";
import "./paymentTypeSwitcher.scss";
import { useDispatch, useSelector } from "react-redux";
import { setPayByCard } from "../../../redux/actions";

const PaymentTypeSwitcher = () => {
  const payByCard = useSelector((state) => state.payByCard);
  const dispatch = useDispatch();

  const togglePaymentMethod = (value) => {
    dispatch(setPayByCard(value)); // Toggle the payment method
  };

  return (
    <div className="payment-type-switcher">
      <div
        className={`switch-btn ${payByCard && "active"}`}
        onClick={() => {
          togglePaymentMethod(true);
        }}
      >
        Pay by Card
      </div>
      <div
        className={`switch-btn ${!payByCard && "active"}`}
        onClick={() => {
          togglePaymentMethod(false);
        }}
      >
        Pay via Bank
      </div>
    </div>
  );
};

export default PaymentTypeSwitcher;
