import React, { useState } from "react";
import "./bankPaymentDrawer.scss";
import BankPaymentContent from "./BankPaymentContent";

const BankPaymentDrawer = ({
  cancel,
  setTipsDrawerActive,
  activeOrder,
  setActiveOrder,
}) => {
  return (
    <div className="bank-payment-drawer">
      <div className="shadow" onClick={cancel}></div>
      <BankPaymentContent
        setTipsDrawerActive={setTipsDrawerActive}
        cancelClick={cancel}
        activeOrder={activeOrder}
        setActiveOrder={setActiveOrder}
      />
    </div>
  );
};

export default BankPaymentDrawer;
