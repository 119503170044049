import React, { useState } from "react";
import BtnCircular from "../../../ds/BtnCircular/BtnCircular";
import { ReactComponent as CloseIcon } from "../../../icons/x.svg";
import BankPaymentBody from "../../molecules/BankPaymentBody/BankPaymentBody";
import { useContent, useLanguages } from "../../../hooks/content";
import BtnStandardIconText from "../../../ds/BtnStandardIconText/BtnStandardIconText";
import { Returntext } from "../../../pages/Landings/common/returnText";
import axios from "axios";
import Lottie from "lottie-react";
import LoaderAnimation from "../../../assets/animations/Loaders/1 - Green/data.json";
import Cookies from "js-cookie";
import { useOrders } from "../../../hooks/orders";
import { useDispatch } from "react-redux";
import { setB2BDrawerStep1, setB2BDrawerStep2 } from "../../../redux/actions";
import { useSnackbar } from "notistack";
import { generateInvoicePDF } from "../../../utils/invoicePDF";

const BankPaymentContent = ({ cancelClick, activeOrder, setActiveOrder }) => {
  const { lang } = useLanguages();
  const { contentManagementData } = useContent();

  const user = JSON.parse(localStorage.getItem("user"));
  const { refetchOrders, orders } = useOrders(
    user.id,
    "RECEIVED_IN_TBILISI_WAREHOUSE"
  );

  const [loader, setLoader] = useState(false);
  const access_token = Cookies.get("access_token");

  const dispatch = useDispatch();

  const { enqueueSnackbar } = useSnackbar();

  const handleTransactionB2BStep2 = async () => {
    try {
      setLoader(true);
      await axios.post(
        `${process.env.REACT_APP_API_URL}/transactions/invoiceStatusChangePending/${activeOrder?.payments[0]?.id}?accessToken=${access_token}`
      );

      await refetchOrders();
      const updatedOrder = orders.find((order) => order.id === activeOrder.id);
      if (updatedOrder) {
        setActiveOrder(updatedOrder);
      }

      dispatch(setB2BDrawerStep1(false));
      dispatch(setB2BDrawerStep2(true));

      setLoader(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      enqueueSnackbar(
        Returntext(contentManagementData, "გადახდა ვერ მოხერხდა", lang),
        {
          variant: "error",
        }
      );
      setLoader(false);
    }
  };

  return (
    <div className={`drawer-wrap ${loader && "load"}`}>
      <div className="header">
        <div className="left"></div>

        <div className="middle">Pay via Bank</div>
        <BtnCircular
          size={"sm"}
          mainColor={"white"}
          svg={<CloseIcon />} // Use the SVG component here
          disabled={false}
          mode={"active"}
          onClick={cancelClick}
        />
      </div>

      {loader && (
        <div className="pd-loader">
          <Lottie animationData={LoaderAnimation} className="animation" />
        </div>
      )}

      <div className="body lg">
        <BankPaymentBody activeOrder={activeOrder} />
      </div>

      <div className={`footer-c `}>
        <BtnStandardIconText
          mode=""
          txt={"Confirm payment"}
          disabled={false}
          size={"md"}
          icon={false}
          mainColor={"green"}
          onClick={handleTransactionB2BStep2}
        />
      </div>
    </div>
  );
};

export default BankPaymentContent;
