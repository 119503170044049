import React, { useEffect, useState } from "react";
import "./timeSlotBox.scss";
import BtnStandardIconText from "../../../ds/BtnStandardIconText/BtnStandardIconText";
import axios from "axios";
import Cookies from "js-cookie";
import { useTimeSlot } from "../../../hooks/ts";
import { Returntext } from "../../../pages/Landings/common/returnText";
import { useContent, useLanguages } from "../../../hooks/content";
import RenderIcon from "./RenderIcon";

const TimeSlotBox = ({ ts, setTs }) => {
  const [dt, setDt] = useState("");
  const [selectedDay, setSelectedDay] = useState(""); // Track the selected day

  const today = new Date(dt);
  const tomorrow = new Date(today);
  tomorrow.setDate(tomorrow.getDate() + 1);

  const { timeSlots } = useTimeSlot();
  const access_token = Cookies.get("access_token");

  const { lang } = useLanguages();
  const { contentManagementData } = useContent();

  useEffect(() => {
    const checkTime = async () => {
      try {
        const res = await axios.get(
          `${process.env.REACT_APP_API_URL}/district/date?accessToken=${access_token}`
        );
        setDt(res.data);
      } catch (err) {
        console.log(err);
      }
    };
    checkTime();
  }, []);

  let month = "";
  let day = "";
  let month2 = "";
  let day2 = "";

  if (dt) {
    const today = new Date(dt);
    const tomorrow = new Date(today);
    tomorrow.setDate(tomorrow.getDate() + 1);

    const dateObjectToday = new Date(today);
    month = dateObjectToday.toLocaleString("default", { month: "short" });
    day = dateObjectToday.getDate();

    const dateObjectTomorrow = new Date(tomorrow);
    month2 = dateObjectTomorrow.toLocaleString("default", { month: "short" });
    day2 = dateObjectTomorrow.getDate();
  }

  const handleSlotSelection = (day, item) => {
    setSelectedDay(day); // Save the selected day
    setTs(item); // Save the selected time slot
  };

  return (
    <div className="time-slot-box">
      {timeSlots?.today?.length > 0 && (
        <div className="time-item">
          <div className="title">
            {month &&
              day &&
              `${day} ${Returntext(contentManagementData, month, lang)}`}
          </div>
          <div className="ts-items">
            {timeSlots?.today?.map((item, i) => (
              <BtnStandardIconText
                key={i}
                mode={""}
                txt={item}
                disabled={false}
                size={"sm"}
                icon={true}
                iconPosition={"right"}
                svg={<RenderIcon iconType={item} />}
                mainColor={
                  ts === item && selectedDay === "today"
                    ? "green"
                    : "grayBorder"
                }
                onClick={() => handleSlotSelection("today", item)}
              />
            ))}
          </div>
        </div>
      )}

      {timeSlots?.tomorrow?.length > 0 && (
        <div className="time-item">
          <div className="title">
            {month2 &&
              day2 &&
              `${day2} ${Returntext(contentManagementData, month2, lang)}`}
          </div>
          <div className="ts-items">
            {timeSlots?.tomorrow?.map((item, i) => (
              <BtnStandardIconText
                key={i}
                mode={""}
                txt={item}
                disabled={false}
                size={"sm"}
                icon={true}
                iconPosition={"right"}
                svg={<RenderIcon iconType={item} />}
                mainColor={
                  ts === item && selectedDay === "tomorrow"
                    ? "green"
                    : "grayBorder"
                }
                onClick={() => handleSlotSelection("tomorrow", item)}
              />
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default TimeSlotBox;
