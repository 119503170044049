import React from "react";
import OrderTableLabel from "../../../ds/OrderTableLabel/OrderTableLabel";
import { useContent, useLanguages } from "../../../hooks/content";
import { Returntext } from "../../../pages/Landings/common/returnText";
import TipsBox from "../TipsBox/TipsBox";
import "./bankPaymentBody.scss";
import { ReactComponent as PcsIcon } from "../../../icons/pcs.svg";
import { ReactComponent as VatIcon } from "../../../icons/vat.svg";
import { ReactComponent as BkpIcon } from "../../../icons/bkp.svg";
import { ReactComponent as EyeIcon } from "../../../icons/eye.svg";
import { ReactComponent as DownloadIcon } from "../../../icons/download2.svg";

import ListItem from "../../../ds/ListItem/ListItem";
import BtnCircular from "../../../ds/BtnCircular/BtnCircular";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { generateInvoicePDF } from "../../../utils/invoicePDF";
import Cookies from "js-cookie";

const BankPaymentBody = ({ activeOrder }) => {
  const { lang } = useLanguages();
  const { contentManagementData } = useContent();

  // Arrow function for handling click
  const handleNavigate = () => {
    const url = `/invoice/${activeOrder.payments[0]?.id}`;
    window.open(url, "_blank");
  };

  return (
    <>
      <TipsBox
        txt={"1"}
        main={"Use this invoice for props"}
        sub={
          "Download or save the invoice and use the details provided to initiate the payment. Ensure you include the reference number when making the transfer to avoid delays."
        }
        icon={<PcsIcon className="icon" />} // Use the SVG component here
      />

      <ListItem
        hasBorder={true}
        hasPadding={true}
        hasIcon={true}
        headline={"Invoice #21314"}
        icon={<BkpIcon />}
        buttons={
          <>
            {" "}
            <BtnCircular
              size={"sm"}
              mainColor={"grayBorder"}
              svg={<EyeIcon />}
              disabled={false}
              onClick={handleNavigate}
            />
            {/* <BtnCircular
              size={"sm"}
              mainColor={"grayBorder"}
              svg={<DownloadIcon />}
              disabled={false}
              onClick={handleDownload}
            /> */}
          </>
        }
      />

      <TipsBox
        txt={"2"}
        main={"Confirm your payment"}
        sub={
          "To confirm a payment, click the “Confirmation of Payment“ button or Confirm your payment at any time from the “Order Details” Page"
        }
        icon={<VatIcon className="icon" />} // Use the SVG component here
      />
    </>
  );
};

export default BankPaymentBody;
