import { createStore } from "redux";

const initialState = {
  trackingDrawerActive: false,
  declareDrawerActive: false,
  customDrawer: false,
  paymentDrawer: false,
  deliveryDrawer: false,
  trackingWay: false,
  aPopUpActive: false,
  payByCard: true,
  b2bDrawerStep1: false, // Add this state
  b2bDrawerStep2: false, // Add this state
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_TRACKING_DRAWER_ACTIVE":
      return { ...state, trackingDrawerActive: action.payload };
    case "SET_DECLARE_DRAWER_ACTIVE":
      return { ...state, declareDrawerActive: action.payload };
    case "SET_CUSTOM_DRAWER":
      return { ...state, customDrawer: action.payload };
    case "SET_PAYMENT_DRAWER":
      return { ...state, paymentDrawer: action.payload };
    case "SET_DELIVERY_DRAWER":
      return { ...state, deliveryDrawer: action.payload };
    case "SET_TRACKING_WAY":
      return { ...state, trackingWay: action.payload };
    case "SET_A_POPUP_ACTIVE":
      return { ...state, aPopUpActive: action.payload };
    case "SET_PAY_BY_CARD":
      return { ...state, payByCard: action.payload };
    case "SET_B2B_DRAWER_STEP1": // Add this case
      return { ...state, b2bDrawerStep1: action.payload };
    case "SET_B2B_DRAWER_STEP2": // Add this case
      return { ...state, b2bDrawerStep2: action.payload };
    default:
      return state;
  }
};

const store = createStore(
  reducer,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);

export default store;
