// actions.js
export const setTrackingDrawerActive = (value) => ({
  type: "SET_TRACKING_DRAWER_ACTIVE",
  payload: value,
});

export const setDeclareDrawerActive = (value) => ({
  type: "SET_DECLARE_DRAWER_ACTIVE",
  payload: value,
});

export const setCustomDrawer = (value) => ({
  type: "SET_CUSTOM_DRAWER",
  payload: value,
});

export const setPaymentDrawer = (value) => ({
  type: "SET_PAYMENT_DRAWER",
  payload: value,
});

export const setDeliveryDrawer = (value) => ({
  type: "SET_DELIVERY_DRAWER",
  payload: value,
});

export const setTrackingWay = (value) => ({
  type: "SET_TRACKING_WAY",
  payload: value,
});

export const setAPopUpActive = (value) => ({
  // Add this action
  type: "SET_A_POPUP_ACTIVE",
  payload: value,
});

export const setPayByCard = (value) => ({
  type: "SET_PAY_BY_CARD",
  payload: value,
});

export const setB2BDrawerStep1 = (value) => ({
  type: "SET_B2B_DRAWER_STEP1",
  payload: value,
});

export const setB2BDrawerStep2 = (value) => ({
  type: "SET_B2B_DRAWER_STEP2",
  payload: value,
});

export const setB2BDrawerStep3 = (value) => ({
  type: "SET_B2B_DRAWER_STEP3",
  payload: value,
});

