import React from "react";
import BtnCircular from "../../../ds/BtnCircular/BtnCircular";
import "./tipsBox.scss";
import { ReactComponent as CloseIcon } from "../../../icons/x.svg";

const TipsBox = ({ txt, main, sub, icon }) => {
  return (
    <div className="tips-box">
      <div className="outer-wrap">
        <BtnCircular
          size={"sm"}
          mainColor={"green"}
          svg={<CloseIcon />} // Use the SVG component here
          disabled={false}
          mode={"active"}
          type={"txt"}
          txt={txt}
          style={{ backgroundColor: "#49A65D" }}
        />

        <div className="tip-info">
          <div className="txts">
            <div className="main">{main}</div>
            <div className="sub">{sub}</div>
          </div>
          {/* Use the SVG component here */}
        </div>
      </div>

      {icon}
    </div>
  );
};

export default TipsBox;
