// eslint-disable-next-line
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import SignIn from "./pages/SignIn/SignIn";
import SignUp from "./pages/SignUp/SignUp";
import Onboarding from "./pages/Onboarding/Onboarding";
import Orders from "./pages/orders/Orders";
import Delivery from "./pages/Landings/Delivery/Delivery";
import Home from "./pages/Landings/Home/Home";
import AboutUS from "./pages/Landings/AboutUS/AboutUS";
import PageNotFound from "./pages/pageNotFound/PageNotFound";
// import TechnicalProblem from "./pages/pageNotFound/TechticalProblem";
import AccountSettings from "./pages/AccountSettings/AccountSettings";
import ShippingAddress from "./pages/ShippingAddress/ShippingAddress";
import DeliverySettings from "./pages/DeliverySettings/DeliverySettings";
// eslint-disable-next-line
import Header from "./components/organisms/Header/Header";
import Prices from "./pages/Landings/Prices/Prices";
import LandingFooter from "./components/organisms/Footer/Footer";
import Services from "./pages/Services/Services";
import Wallet from "./pages/Wallet/Wallet";
import { useUserData } from "./hooks/users";
import PasswordReset from "./pages/PasswordReset/PasswordReset";
import { useEffect, useState } from "react";
import SettlePaymentDrawer from "./components/organisms/SettlePaymentDrawer/SettlePaymentDrawer";
import AuthLayout from "./layouts/AuthLayout";
import SignInMobile from "./pages/SignIn/SignInMobile";
import MainLayout from "./layouts/MainLayout";
import HeaderLine from "./components/organisms/HeaderLine/headerLine";
import OnboardingLayout from "./layouts/OnboardingLayout";
import "./app.scss";
import LoaderAnimation from "./assets/animations/Loaders/1 - Green/data.json";
import Lottie from "lottie-react";
import TermsAndConditions from "./pages/Landings/TermsAndConditions/TermsAndConditions";
import AddressOn from "./pages/AddressOn/AddressOn";
import { useSelector } from "react-redux";
import DixaSript from "./hooks/dixaInt";
import Invoice from "./components/organisms/BankPaymentDrawer/invoice";
import DirectPdfExporter from "./components/organisms/BankPaymentDrawer/exporter";
import PdfExporter from "./components/organisms/BankPaymentDrawer/exporter";
import VerticalMultiPagePdfExporter from "./components/organisms/BankPaymentDrawer/exporter";

const App = () => {
  // eslint-disable-next-line
  const { isLoading } = useUserData();
  const userData = JSON.parse(localStorage.getItem("user"));
  const [pDrawerActive, setPDrawerActive] = useState(false);
  const [popUpActive, setPopUpActive] = useState(false);
  const [restrictedItemsDrawer, setRestrictedItemsDrawer] = useState(false);

  const [showModal, setShowModal] = useState(false);
  const [orderId, setOrderId] = useState("");
  const [deleteItem, setDeleteItem] = useState(false);
  const [activeItem, setActiveItem] = useState(false);
  const [addAddressPopUp, setAddAddressPopUp] = useState(false);
  const [editAddressPopUp, setEditAddressPopUp] = useState(false);
  const [activeOrder, setActiveOrder] = useState([]);
  const [vatDrawer, setVatDrawer] = useState(false);
  const [deleteOrder, setDeleteOrder] = useState(false);
  const [payedDrawer, setPayedDrawer] = useState(false);
  const [declareClose, setDeclareClose] = useState(false);
  //
  //
  const [loading, setLoading] = useState(true);

  // useEffect(() => {
  //   if (userData) {
  //     window._dixa_.invoke("setUserIdentity", {
  //       type: "verified",
  //       payload: { type: "jwe", token: userData.dixaToken },
  //     });
  //   }
  // }, [userData]);

  // DixaSript();

  useEffect(() => {
    const checkIfLoaded = () => {
      if (document.readyState === "complete") {
        console.log("Document is fully loaded");
        // Set a timeout of 1 second after the bundle load
        setTimeout(() => {
          console.log("1-second timeout complete, deactivating loader");
          setLoading(false);
        }, 2000); // 1 second timeout
      } else {
        console.log("Document is still loading");
      }
    };

    // Check if the document is already loaded
    checkIfLoaded();

    // Add an event listener as a fallback for when the load event fires
    const handleBundleLoad = () => {
      console.log("Bundle load event triggered");
      // Set a timeout of 1 second after the bundle load
      setTimeout(() => {
        console.log("1-second timeout complete, deactivating loader");
        setLoading(false);
      }, 2000); // 1 second timeout
    };

    window.addEventListener("load", handleBundleLoad);

    return () => {
      window.removeEventListener("load", handleBundleLoad);
      console.log("Event listener removed for window load");
    };
  }, []);

  const isLandingRoute = () => {
    return window.location.pathname.startsWith("/landing");
  };

  return (
    <div>
      {loading && (
        <div className="animation-container_app_main">
          <Lottie animationData={LoaderAnimation} className="animation" />
        </div>
      )}

      <Router>
        {pDrawerActive && (
          <SettlePaymentDrawer
            cancelClick={() => {
              setPDrawerActive(false);
              setActiveOrder([]);
            }}
            activeOrder={activeOrder}
            setActiveOrder={setActiveOrder}
          />
        )}

        <Routes>
          {/* <Route
            path="/"
            element={
              userData ? <Navigate to="/orders/AWAITING" /> : <Navigate to="/sign-in" />
            }
          /> */}
          <Route
            path="/"
            element={
              userData ? (
                <Navigate to="/orders/AWAITING" />
              ) : (
                <>
                  <HeaderLine />
                  <Header />
                  <Home />
                  <LandingFooter />
                </>
              )
            }
          />
          <Route
            path="/sign-in"
            element={
              !userData ? (
                <AuthLayout web={<SignIn />} mob={<SignInMobile />} />
              ) : (
                <Navigate to="/orders/AWAITING" />
              )
            }
          />
          <Route
            path="/sign-up"
            element={
              !userData ? <SignUp /> : <Navigate to="/orders/AWAITING" />
            }
          />
          <Route
            path="/password-reset/:type"
            element={
              !userData ? <PasswordReset /> : <Navigate to="/orders/AWAITING" />
            }
          />

          {userData && !userData.verified && (
            <Route
              path="/orders/:status"
              element={<Navigate to={"/onboarding"} />}
            />
          )}

          {userData && userData.verified ? (
            <>
              <Route
                path="/onboarding"
                element={<Navigate to="/orders/AWAITING" />}
              />
              <Route
                path="/orders"
                element={<Navigate to="/orders/AWAITING" />}
              />
              <Route
                path="/onboarding/delivery-address"
                element={
                  userData ? (
                    <OnboardingLayout web={<AddressOn />} mob={<AddressOn />} />
                  ) : (
                    <Navigate to="/sign-in" />
                  )
                }
              />
              <Route
                path="/orders/:status"
                element={
                  userData ? (
                    <MainLayout
                      web={
                        <Orders
                          activeOrder={activeOrder}
                          setActiveOrder={setActiveOrder}
                          setVatDrawer={setVatDrawer}
                          setDeleteItem={setDeleteItem}
                          setActiveItem={setActiveItem}
                          deleteOrder={deleteOrder}
                          setDeleteOrder={setDeleteOrder}
                          payedDrawer={payedDrawer}
                          setPayedDrawer={setPayedDrawer}
                        />
                      }
                      mob={
                        <Orders
                          activeOrder={activeOrder}
                          setActiveOrder={setActiveOrder}
                          setDeleteItem={setDeleteItem}
                          setActiveItem={setActiveItem}
                          deleteOrder={deleteOrder}
                          setDeleteOrder={setDeleteOrder}
                          payedDrawer={payedDrawer}
                          setPayedDrawer={setPayedDrawer}
                        />
                      }
                      setPDrawerActive={setPDrawerActive}
                      activeOrder={activeOrder}
                      setActiveOrder={setActiveOrder}
                      setVatDrawer={setVatDrawer}
                      vatDrawer={vatDrawer}
                      setDeleteItem={setDeleteItem}
                      deleteItem={deleteItem}
                      deleteOrder={deleteOrder}
                      setDeleteOrder={setDeleteOrder}
                      setActiveItem={setActiveItem}
                      activeItem={activeItem}
                      payedDrawer={payedDrawer}
                      setPayedDrawer={setPayedDrawer}
                      declareClose={declareClose}
                      setDeclareClose={setDeclareClose}
                      pDrawerActive={pDrawerActive}
                    />
                  ) : (
                    <Navigate to="/sign-in" />
                  )
                }
              />
              <Route
                path="/account-settings"
                element={
                  userData ? (
                    <MainLayout
                      web={<AccountSettings setPopUpActive={setPopUpActive} />}
                      mob={<AccountSettings setPopUpActive={setPopUpActive} />}
                      setPDrawerActive={setPDrawerActive}
                      popUpActive={popUpActive}
                      setPopUpActive={setPopUpActive}
                      pDrawerActive={pDrawerActive}
                    />
                  ) : (
                    <Navigate to="/sign-in" />
                  )
                }
              />
              <Route
                path="/shipping-address"
                element={
                  userData ? (
                    <MainLayout
                      web={
                        <ShippingAddress
                          restrictedItemsDrawer={restrictedItemsDrawer}
                          setRestrictedItemsDrawer={setRestrictedItemsDrawer}
                        />
                      }
                      mob={
                        <ShippingAddress
                          restrictedItemsDrawer={restrictedItemsDrawer}
                          setRestrictedItemsDrawer={setRestrictedItemsDrawer}
                        />
                      }
                      setPDrawerActive={setPDrawerActive}
                      restrictedItemsDrawer={restrictedItemsDrawer}
                      setRestrictedItemsDrawer={setRestrictedItemsDrawer}
                      pDrawerActive={pDrawerActive}
                    />
                  ) : (
                    <Navigate to="/sign-in" />
                  )
                }
              />
              <Route
                path="/delivery"
                element={
                  userData ? (
                    <MainLayout
                      web={
                        <DeliverySettings
                          setDeleteItem={setDeleteItem}
                          deleteItem={deleteItem}
                          setActiveItem={setActiveItem}
                          activeItem={activeItem}
                          setAddAddressPopUp={setAddAddressPopUp}
                          setEditAddressPopUp={setEditAddressPopUp}
                        />
                      }
                      mob={
                        <DeliverySettings
                          setDeleteItem={setDeleteItem}
                          deleteItem={deleteItem}
                          setActiveItem={setActiveItem}
                          activeItem={activeItem}
                          setAddAddressPopUp={setAddAddressPopUp}
                          setEditAddressPopUp={setEditAddressPopUp}
                        />
                      }
                      setPDrawerActive={setPDrawerActive}
                      setDeleteItem={setDeleteItem}
                      deleteItem={deleteItem}
                      setActiveItem={setActiveItem}
                      activeItem={activeItem}
                      setAddAddressPopUp={setAddAddressPopUp}
                      setEditAddressPopUp={setEditAddressPopUp}
                      addAddressPopUp={addAddressPopUp}
                      editAddressPopUp={editAddressPopUp}
                      pDrawerActive={pDrawerActive}
                    />
                  ) : (
                    <Navigate to="/sign-in" />
                  )
                }
              />
              <Route
                path="/invoice/:id"
                element={userData ? <Invoice /> : <Navigate to="/sign-in" />}
              />
              <Route
                path="/services"
                element={
                  userData ? (
                    <MainLayout
                      web={<Services />}
                      mob={<Services />}
                      setPDrawerActive={setPDrawerActive}
                      setde
                    />
                  ) : (
                    <Navigate to="/sign-in" />
                  )
                }
              />

              <Route
                path="/wallet"
                element={
                  userData ? (
                    <MainLayout
                      web={
                        <Wallet
                          setDeleteItem={setDeleteItem}
                          setOrderId={setOrderId}
                          setShowModal={setShowModal}
                          setPopUpActive={setPopUpActive}
                          deleteItem={deleteItem}
                          setActiveItem={setActiveItem}
                          activeItem={activeItem}
                          setPDrawerActive={setPDrawerActive}
                        />
                      }
                      mob={
                        <Wallet
                          setDeleteItem={setDeleteItem}
                          setOrderId={setOrderId}
                          setShowModal={setShowModal}
                          setPopUpActive={setPopUpActive}
                          deleteItem={deleteItem}
                          setActiveItem={setActiveItem}
                          activeItem={activeItem}
                          setPDrawerActive={setPDrawerActive}
                        />
                      }
                      setPDrawerActive={setPDrawerActive}
                      setDeleteItem={setDeleteItem}
                      setOrderId={setOrderId}
                      setShowModal={setShowModal}
                      orderId={orderId}
                      showModal={showModal}
                      activeItem={activeItem}
                      deleteItem={deleteItem}
                      pDrawerActive={pDrawerActive}
                    />
                  ) : (
                    <Navigate to="/sign-in" />
                  )
                }
              />
            </>
          ) : (
            <>
              <Route
                path="/onboarding"
                element={
                  !userData ? (
                    <Navigate to="/sign-in" />
                  ) : (
                    <OnboardingLayout
                      web={<Onboarding setLoader={setLoading} />}
                      mob={<Onboarding setLoader={setLoading} />}
                    />
                  )
                }
              />

              <Route path="/onboarding" element={<Navigate to="/sign-in" />} />
              <Route
                path="/*"
                element={
                  isLandingRoute() ? null : (
                    <Navigate to="/onboarding" replace />
                  )
                }
              />
            </>
          )}
          <Route path="landing/*" element={<LandingRoutes />} />
          <Route path="*" element={<PageNotFound />} />
        </Routes>
      </Router>
    </div>
  );
};
const LandingRoutes = () => {
  return (
    <>
      <HeaderLine />
      <Header />
      <Routes>
        <Route path="home" element={<Home />} />
        <Route path="delivery" element={<Delivery />} />
        <Route path="about" element={<AboutUS />} />
        <Route path="prices" element={<Prices />} />
        <Route path="terms" element={<TermsAndConditions />} />
        <Route path="*" element={<PageNotFound />} />
      </Routes>
      <LandingFooter />
    </>
  );
};

export default App;
