import React, { useState } from "react";
import "./orderPriceDetails.scss";
import ListItem from "../../../ds/ListItem/ListItem";
import PriceItem from "../../atoms/PriceItem/PriceItem";
import { useContent, useLanguages } from "../../../hooks/content";
import { Returntext } from "../../../pages/Landings/common/returnText";
import { ReactComponent as UpAIcon } from "../../../icons/up-a.svg";
import { ReactComponent as DownAIcon } from "../../../icons/down-a.svg";
import { useOrders } from "../../../hooks/orders";
import { useParams } from "react-router-dom";
import { ReactComponent as DownloadIcon } from "../../../icons/download4.svg";
import BtnStandardIconText from "../../../ds/BtnStandardIconText/BtnStandardIconText";

const PayedPriceDetailsB2b = ({ order, i, actives, all }) => {
  const { lang } = useLanguages();
  const { contentManagementData } = useContent();
  const [clicked, setClicked] = useState(false);
  const { status } = useParams();

  const user = JSON.parse(localStorage.getItem("user"));
  const { orders, ordersLoading } = useOrders(user?.id, status);

  const [selectedOrder, setSelectedOrder] = useState(null);

  // Example: get order with ID=2 (this could be dynamic in real scenarios)
  const getOrderById = (orderId) => {
    const foundOrder = orders.find((order) => order.id === orderId);
    setSelectedOrder(foundOrder || null);
  };

  const handleNavigate = () => {
    // Convert the entire order object to a JSON string and store it in sessionStorage
    sessionStorage.setItem("selectedOrder", JSON.stringify(order));
    sessionStorage.setItem(
      "invoice_num",
      `${selectedOrder?.payments[0]?.order_id_external}-${i + 1}`
    );
    sessionStorage.setItem(
      "invoice_date",
      `${selectedOrder?.payments[0]?.created_at}`
    );
    // Open the new page (e.g., /single-invoice) in a separate tab
    window.open("/single-invoice", "_blank");
  };

  console.log(selectedOrder);

  return (
    <div className={`order-price-details ${clicked && "active"}`}>
      <ListItem
        hasBorder={clicked ? false : true}
        hasPadding={clicked ? false : true}
        hasIcon={true}
        hasHover={clicked ? false : true}
        icon={<></>}
        headline={order.name}
        description={`${order?.tracking_number} (${order.weight}  ${Returntext(
          contentManagementData,
          "Kg",
          lang
        )})`}
        buttons={
          <div className="opd-wrap">
            <span className="opd-price">{order?.price_gz}₾</span>
            {clicked ? <UpAIcon /> : <DownAIcon />}
          </div>
        }
        onClick={() => {
          getOrderById(order.id);
          setClicked(!clicked);
        }}
      />

      {clicked && (
        <>
          <div className="line"></div>
          <PriceItem
            left={`${Returntext(
              contentManagementData,
              "transportation",
              lang
            )}: ${order.weight} ${Returntext(
              contentManagementData,
              "Kg",
              lang
            )}`}
            right={`${order?.price_gz}₾`}
          />
          <PriceItem
            left={Returntext(contentManagementData, "Repackaging", lang)}
            right={"0.00₾"}
          />
          <PriceItem
            left={Returntext(contentManagementData, "Insurance", lang)}
            right={"0.00₾"}
          />

          <div className="line"></div>

          {selectedOrder?.payments[0]?.invoice && (
            <ListItem
              hasBorder={false}
              hasPadding={false}
              hasIcon={true}
              hasHover={false}
              icon={<></>}
              headline={`${selectedOrder?.payments[0]?.order_id_external}-${
                i + 1
              }`}
              description={`${selectedOrder?.tracking_number} (${
                selectedOrder.weight
              }  ${Returntext(contentManagementData, "Kg", lang)})`}
              onClick={() => setClicked(!clicked)}
              buttons={
                <BtnStandardIconText
                  mode=""
                  txt="Download"
                  disabled={false}
                  size="sm"
                  icon={true}
                  mainColor="black"
                  iconPosition={"right"}
                  svg={<DownloadIcon />}
                  onClick={handleNavigate}
                />
              }
            />
          )}
        </>
      )}
    </div>
  );
};

export default PayedPriceDetailsB2b;
