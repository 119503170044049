import React, { useEffect } from "react";
import "./transactionsBody.scss";
import AccountDetailsBoxTitle from "../../atoms/AccountDetailsBoxTitle/AccountDetailsBoxTitle";
import NoItems from "../../../ds/NoItems/NoItems";
import ListItem from "../../../ds/ListItem/ListItem";
import { ReactComponent as WalletIcon } from "../../../icons/wallet.svg";
import { ReactComponent as NoTransactionIcon } from "../../../icons/no-transaction.svg";
import { useTransactions } from "../../../hooks/transactions";
import DateComponent from "./DateComponent";
import { useContent, useLanguages } from "../../../hooks/content";
import { Returntext } from "../../../pages/Landings/common/returnText";
import { ReactComponent as DownloadIcon } from "../../../icons/download3.svg";
import BtnStandardIconText from "../../../ds/BtnStandardIconText/BtnStandardIconText";

const TransactionsBody = () => {
  const { transactions } = useTransactions();
  const { lang } = useLanguages();
  const { contentManagementData } = useContent();
  const handleNavigate = (id) => {
    const url = `/invoice/${id}`;
    window.open(url, "_blank");
  };

  return (
    <div className="transactions-body">
      <AccountDetailsBoxTitle
        main={Returntext(contentManagementData, "transactionsH1", lang)}
        sub={Returntext(contentManagementData, "transactionsB1", lang)}
      />

      {transactions.length === 0 && (
        <NoItems
          icon={NoTransactionIcon}
          main={Returntext(contentManagementData, "transactionsNH1", lang)}
          sub={Returntext(contentManagementData, "transactionsNB 1", lang)}
        />
      )}

      <div className="transactions-wrap">
        {transactions.length > 0 &&
          transactions.map((tInfo, i) => (
            <div className="transaction-item" key={i}>
              <DateComponent airwayDate={tInfo.date} />
              {tInfo.transactions.length > 0 &&
                tInfo.transactions.map((trans, j) => (
                  <>
                    <ListItem
                      hasBorder={false}
                      hasPadding={false}
                      hasIcon={true}
                      headline={Returntext(
                        contentManagementData,
                        "topUp",
                        lang
                      )}
                      description={`${trans.amount} ₾`}
                      icon={<WalletIcon />} // Use the SVG component here
                      // buttons={<span className="inv"> Invoice</span>}
                      buttons={
                        <BtnStandardIconText
                          mode=""
                          txt="Download"
                          disabled={false}
                          size="sm"
                          icon={true}
                          mainColor="grayBorder"
                          iconPosition={"right"}
                          svg={<DownloadIcon />}
                          onClick={() => handleNavigate(trans.id)}
                        />
                      }
                    />
                    <div className="break" />
                  </>
                ))}
            </div>
          ))}
      </div>
    </div>
  );
};

export default TransactionsBody;
