import React, { useEffect, useState } from "react";
import "./paymentNusxa.scss";
import { ReactComponent as ScrollA } from "../../../icons/scroll-a.svg";
import PayedPriceDetails from "../PayedPriceDetails/PayedPriceDetails";
import PayedPriceDetailsB2b from "../PayedPriceDetails/PayedPriceDetailsB2b";

const PayedOrdersB2b = ({ main, sub, selectedOrders, all }) => {
  return (
    <div className={`payed-orders ${selectedOrders.length > 2 && "lg"}`}>
      <div className="txts">
        <div className="main">{main}</div>
      </div>

      <div className="items-c">
        <div className="items">
          {selectedOrders?.map((order, i) => (
            <PayedPriceDetailsB2b order={order} i={i} all={all} />
          ))}
        </div>
        {selectedOrders.length > 2 && <ScrollA className="icon" />}
      </div>
    </div>
  );
};

export default PayedOrdersB2b;
