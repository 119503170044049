import React, { useRef, useEffect } from "react";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import Invoice from "./invoice";

function cropCanvas(canvas, tolerance = 0) {
  const ctx = canvas.getContext("2d");
  const { width, height } = canvas;
  const imageData = ctx.getImageData(0, 0, width, height).data;

  let top = null,
    left = null,
    right = null,
    bottom = null;

  for (let y = 0; y < height; y++) {
    for (let x = 0; x < width; x++) {
      const index = (y * width + x) * 4;
      const r = imageData[index];
      const g = imageData[index + 1];
      const b = imageData[index + 2];
      const a = imageData[index + 3];

      if (
        !(
          r >= 255 - tolerance &&
          g >= 255 - tolerance &&
          b >= 255 - tolerance &&
          a === 255
        )
      ) {
        if (top === null) top = y;
        if (left === null || x < left) left = x;
        if (right === null || x > right) right = x;
        bottom = y;
      }
    }
  }

  if (top === null) return canvas;

  const cropWidth = right - left + 1;
  const cropHeight = bottom - top + 1;

  const croppedCanvas = document.createElement("canvas");
  croppedCanvas.width = cropWidth;
  croppedCanvas.height = cropHeight;
  const croppedCtx = croppedCanvas.getContext("2d");

  croppedCtx.drawImage(
    canvas,
    left,
    top,
    cropWidth,
    cropHeight,
    0,
    0,
    cropWidth,
    cropHeight
  );
  return croppedCanvas;
}

const VerticalMultiPagePdfExporter = () => {
  const offscreenRef = useRef();

  useEffect(() => {
    const timeout = setTimeout(() => {
      generatePdf();
    }, 1000);
    return () => clearTimeout(timeout);
  }, []);

  const generatePdf = () => {
    const element = offscreenRef.current;
    html2canvas(element, { scale: 2 }).then((canvas) => {
      const croppedCanvas = cropCanvas(canvas);
      const imgData = croppedCanvas.toDataURL("image/png");

      const pdf = new jsPDF("p", "pt", "a4");
      const pageWidth = pdf.internal.pageSize.getWidth();
      const pageHeight = pdf.internal.pageSize.getHeight();

      const ratio = pageWidth / croppedCanvas.width;
      const scaledWidth = pageWidth;
      const scaledHeight = croppedCanvas.height * ratio;

      let remainingHeight = scaledHeight;
      let positionY = 0;

      pdf.addImage(imgData, "PNG", 0, 0, scaledWidth, scaledHeight);
      remainingHeight -= pageHeight;

      while (remainingHeight > 0) {
        positionY = scaledHeight - remainingHeight;
        pdf.addPage();
        pdf.addImage(imgData, "PNG", 0, -positionY, scaledWidth, scaledHeight);
        remainingHeight -= pageHeight;
      }

      pdf.save("invoice.pdf");
    });
  };

  return (
    <div
      ref={offscreenRef}
      style={{
        position: "absolute",
        top: "-10000px",
        left: "-10000px",
        width: "auto",
        margin: 0,
        padding: 0,
        overflow: "hidden",
      }}
    >
      <Invoice />
    </div>
  );
};

export default VerticalMultiPagePdfExporter;
