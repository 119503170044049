import React, { useState } from "react";
import BtnCircular from "../../../ds/BtnCircular/BtnCircular";
import { ReactComponent as CloseIcon } from "../../../icons/x.svg";
import BankPaymentBody from "../../molecules/BankPaymentBody/BankPaymentBody";
import { useContent, useLanguages } from "../../../hooks/content";
import BtnStandardIconText from "../../../ds/BtnStandardIconText/BtnStandardIconText";
import { Returntext } from "../../../pages/Landings/common/returnText";
import axios from "axios";
import Lottie from "lottie-react";
import LoaderAnimation from "../../../assets/animations/Loaders/1 - Green/data.json";
import Cookies from "js-cookie";
import { useOrders } from "../../../hooks/orders";
import { useDispatch } from "react-redux";
import { setB2BDrawerStep1, setB2BDrawerStep2 } from "../../../redux/actions";
import { useSnackbar } from "notistack";
import { ReactComponent as BkpIcon } from "../../../icons/bkp.svg";
import ListItem from "../../../ds/ListItem/ListItem";
import { ReactComponent as EyeIcon } from "../../../icons/eye.svg";
import { ReactComponent as DownloadIcon } from "../../../icons/download2.svg";
import { ReactComponent as SusIcon } from "../../../icons/sus.svg";

const BankPaymentContentS2 = ({ cancelClick, activeOrder, setActiveOrder }) => {
  const { lang } = useLanguages();
  const { contentManagementData } = useContent();

  const user = JSON.parse(localStorage.getItem("user"));
  const { refetchOrders, orders } = useOrders(
    user.id,
    "RECEIVED_IN_TBILISI_WAREHOUSE"
  );

  const [loader, setLoader] = useState(false);
  const access_token = Cookies.get("access_token");

  const dispatch = useDispatch();

  const { enqueueSnackbar } = useSnackbar();

  const handleTransactionB2BStep2 = async () => {
    try {
      setLoader(true);
      await axios.post(
        `${process.env.REACT_APP_API_URL}/transactions/invoiceStatusChangeReject/${activeOrder?.payments[0]?.id}?accessToken=${access_token}`
      );

      await refetchOrders();
      const updatedOrder = orders.find((order) => order.id === activeOrder.id);
      if (updatedOrder) {
        setActiveOrder(updatedOrder);
      }

      setLoader(false);
      dispatch(setB2BDrawerStep2(false));

      enqueueSnackbar(Returntext(contentManagementData, "გაუქმებულია", lang), {
        variant: "success",
      });
    } catch (error) {
      console.error("Error fetching data:", error);
      enqueueSnackbar(
        Returntext(contentManagementData, "გადახდა ვერ მოხერხდა", lang),
        {
          variant: "error",
        }
      );
      setLoader(false);
    }
  };

  // Arrow function for handling click
  const handleNavigate = () => {
    const url = `/invoice/${activeOrder.payments[0]?.id}`;
    window.open(url, "_blank");
  };

  return (
    <div className={`drawer-wrap-2 ${loader && "load"}`}>
      <div className="header">
        <div className="left"></div>

        <div className="middle">Pay via Bank</div>
        <BtnCircular
          size={"sm"}
          mainColor={"white"}
          svg={<CloseIcon />} // Use the SVG component here
          disabled={false}
          mode={"active"}
          onClick={cancelClick}
        />
      </div>

      {loader && (
        <div className="pd-loader">
          <Lottie animationData={LoaderAnimation} className="animation" />
        </div>
      )}

      <div className="body lg">
        <div className="tp-txt">
          <div className="mt">Your invoice is ready</div>
          <div className="st">
            The payment has already been completed. The invoice will always be
            available in the orders section, where you can download or save it
            for your records.
          </div>
        </div>
        <ListItem
          hasBorder={true}
          hasPadding={true}
          hasIcon={true}
          headline={"Invoice #21314"}
          icon={<BkpIcon />}
          buttons={
            <>
              <BtnCircular
                size={"sm"}
                mainColor={"grayBorder"}
                svg={<EyeIcon />}
                disabled={false}
                onClick={handleNavigate}
              />
              {/* <BtnCircular
                size={"sm"}
                mainColor={"grayBorder"}
                svg={<DownloadIcon />}
                disabled={false}
              /> */}
            </>
          }
        />

        <div className="suspend-box">
          <SusIcon className="sus" />

          <div className="txts">
            <div className="mn">We are reviewing your payment</div>
            <div className="sn">
              We are verifying your payment invoice to ensure it matches your
              order. Once confirmed, you can proceed to select your delivery
              time.
            </div>
          </div>

          <BtnStandardIconText
            mode=""
            txt={"Suspend Payment"}
            disabled={false}
            size={"md"}
            icon={false}
            mainColor={"red"}
            onClick={handleTransactionB2BStep2}
          />
        </div>
      </div>
    </div>
  );
};

export default BankPaymentContentS2;
